import axios from 'axios'
import NProgress from 'accessible-nprogress'
import store from '@/store'
import Vue from 'vue'
// import router from "@/router";
/**
 *
 * Nanti belum ada deskripsi
 */
export default async function({
  url = process.env.VUE_APP_BASE_GRAPHQL,
  query = '',
  variables = {},
  token = store.state.authService && store.state.authService.token,
  showToastMessage = true
} = {}) {
  /**
   * Memulai Loading Progress ( yang diatas )
   */
  NProgress.start()
  /**
   * request dimasukan ke dalam variable data, menggunakan await untuk menunggu progress get sebelum return nilai dibawah nanti
   */
  // console.groupCollapsed(
  //   `%cRequest Start ( ${query
  //     ?.split("{")?.[1]
  //     ?.slice(0, -1)
  //     ?.split("}")?.[0]
  //     .trim()} )`,
  //   "background: #007bff; color: #ffffff; border-radius: 5px; padding:4px 8px;display: inline-block;"
  // );
  // console.count("Total Request");
  // console.log({
  //   query: `${query}`,
  //   variables: variables,
  //   path: router.currentRoute.path,
  //   name: router.currentRoute.name
  // });
  // console.groupEnd("Request Start");
  const data = await axios({
    url: url,
    method: 'post',
    data: {
      query: query,
      variables: variables
    },
    headers: {
      Authorization: 'Bearer ' + token,
      'Access-Control-Allow-Origin': process.env.VUE_APP_URL_ORIGIN ?? '*',
      'Access-Control-Allow-Credentials': true
    }
  })
    .then(result => {
      NProgress.done()
      return result
    })
    .catch(err => {
      NProgress.done()
      return err
    })

  const localError = [
    {
      id: 101,
      browser: 'Network Error',
      client: 'Koneksi bermasalah. <br/>Cek jaringan atau coba sebentar lagi'
    },
    {
      id: 102,
      browser: "Cannot read property 'protocol' of undefined",
      client: 'Terjadi kesalahan pada sistem. <br/> Coba beberapa saat lagi.'
    }
  ]
  if (data.message) {
    const error = localError.find(error => error.browser === data.message) || {
      id: 991,
      client: 'Terjadi kesalahan pada sistem. <br/> Coba beberapa saat lagi.'
    }
    console.groupCollapsed(
      `%cRequest Error ( ${query
        ?.split('{')?.[1]
        ?.slice(0, -1)
        ?.split('}')?.[0]
        .trim()} )`,
      'background: #dc3435; color: #ffffff; border-radius: 5px; padding:4px 8px;display: inline-block;'
    )
    console.log(
      'Terdapat masalah pada server atau client, bukan masalah graphQL,\nsilahkan coba beberapa solusi dibawah ini :'
    )
    console.group('Solusi')
    console.log('1. Jaringan Bermasalah, coba periksa VPN')
    console.log(
      '2. ENV belum di konfigurasi, coba di cek apakah env sudah benar'
    )
    console.log(
      '3. Jika masalah masih berlanjut kemungkinan masalah server, hubungi Lead FE atau DevOps'
    )
    console.groupEnd('Solusi')

    console.groupEnd('Request Error')
    if (showToastMessage) {
      Vue.$toast.error(error.client)
    }
    error.action && error.action()
    // eslint-disable-next-line no-throw-literal
    throw { message: error.client, id: error.id }
  }

  /**
   * Loading Progress Selesai karna get data selesai
   */
  NProgress.done()
  const errorMessage = data.data.errors ? data.data.errors[0].message : ''
  const serverError = [
    {
      id: 103,
      server: 'Sesi telah berakhir',
      client: 'Sesi telah berakhir',
      disabled: true,
      action: () => store.dispatch('authService/postLogout', false)
    },
    {
      id: 1031,
      server: 'Sesi anda telah berakhir',
      client: 'Sesi anda telah berakhir',
      disabled: true,
      action: () => store.dispatch('authService/postLogout', false)
    },
    {
      id: 104,
      server: 'User tidak ditemukan.',
      client: 'User tidak ditemukan'
    },
    {
      id: 105,
      server: 'Periksa kembali email/kata sandi anda',
      client: 'Periksa kembali email/kata sandi anda'
    },
    {
      id: 106,
      server:
        'duplicate key value violates unique constraint "kd_pulau_unique"',
      client: 'Kode pulau sudah digunakan'
    },
    {
      id: 107,
      server: 'jwt malformed',
      client: 'Sesi Telah berakhir'
    },
    {
      id: 108,
      server: 'Silahkan ganti kata sandi anda',
      client: 'Silahkan ganti kata sandi anda'
    },
    {
      id: 109,
      server: 'Kata sandi baru tidak boleh menggunakan kata sandi default',
      client: 'Kata sandi baru tidak boleh menggunakan kata sandi default.'
    },
    {
      id: 110,
      server: 'Silahkan login terlebih dahulu',
      client: 'Silahkan login terlebih dahulu.'
    },
    {
      id: 111,
      server:
        'duplicate key value violates unique constraint "kd_propinsi_unique"',
      client: 'Kode provinsi sudah digunakan.'
    },
    {
      id: 112,
      server:
        "Cannot destructure property 'userEmail' of '(intermediate value)' as it is undefined.",
      client: 'Token tidak valid.'
    },
    {
      id: 113,
      server: 'Penilaian Belum Dilakukan',
      disabled: true
    },
    {
      id: 115,
      find: 'Cannot read property',
      client: 'Terjadi kesalahan pada sistem'
    },
    {
      id: 116,
      server: 'jwt expired',
      client: 'Sesi Telah berakhir'
    },
    {
      id: 119,
      find: 'connect ECONNREFUSED',
      client: 'Koneksi bermasalah. <br/>Cek jaringan atau coba sebentar lagi'
    },
    {
      id: 120,
      find: 'does not exist',
      client: 'Terdapat kesalahan data'
    },
    {
      id: 121,
      find: 'column reference',
      client: 'Terdapat kesalahan data'
    },
    {
      id: 122,
      find: 'duplicate key value violates',
      client: 'Terdapat duplikat data'
    }
  ]
  if (errorMessage) {
    const error = serverError.find(item => {
      if (item.find !== undefined) {
        return (
          errorMessage.toLowerCase().indexOf(item.find?.toLowerCase()) !== -1
        )
      }
      return item.server === errorMessage
    }) || {
      id: 999,
      server: errorMessage,
      client: errorMessage,
      default: true
    }
    console.groupCollapsed(
      `%cRequest Error ( ${query
        ?.split('{')?.[1]
        ?.slice(0, -1)
        ?.split('}')?.[0]
        .trim()} )`,
      'background: #dc3435; color: #ffffff; border-radius: 5px; padding:4px 8px;display: inline-block;'
    )
    console.log(error)
    console.groupEnd('Request Error')
    /**
     * @description
     * 📝 Error dari backend berupa string dan bukan object \
     * 📝 Ketika error message lebih dari satu pada backend, dipisahkan oleh tanda ' | ' \
     * 📝 Maka dari itu, di split menggunakan ' | ' supaya menjadi array, dan bisa \
     * menampilkan multiple toast message ketika error message dari backend lebih \
     * dari satu
     */
    if (!error.disabled && showToastMessage) {
      for (const message of error.client.split('|')) {
        Vue.$toast.error(message)
      }
    }

    if (error.action) {
      error.action()
    }
    if (error.default) {
      // eslint-disable-next-line no-throw-literal
      throw {
        gql: { query, variables, error },
        message: error.server,
        id: error.id,
        serverMessage: errorMessage
      }
    } else {
      // eslint-disable-next-line no-throw-literal
      throw {
        gql: { query, variables, error },
        message: error.client,
        id: error.id,
        serverMessage: errorMessage
      }
    }
  }

  /**
   * Axios mengembalikan data dengan dibungkus dalam object data,
   * sedangkan response nya dibungkus dalam data juga
   * maka untuk mengambil data yang asli menggunakan data.data
   */
  // console.groupCollapsed(
  //   `%cRequest Berhasil ( ${query
  //     ?.split("{")?.[1]
  //     ?.slice(0, -1)
  //     ?.split("}")?.[0]
  //     .trim()} )`,
  //   "background: #287D3C; color: #ffffff; border-radius: 5px; padding:4px 8px;display: inline-block;"
  // );
  // console.log({
  //   query: query,

  //   variables: variables,
  //   data: data.data,
  //   path: router.currentRoute.path,
  //   name: router.currentRoute.name
  // });
  // console.groupEnd("Request Berhasil");
  return data.data
}
