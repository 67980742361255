import graphqlQuery from '@/services/Api'
import router from '@/router'
// import Vue from 'vue'
import axios from 'axios'
export default {
  namespaced: true,

  /**
   * Menyimpan value state.
   */
  state: () => ({
    /**
     * Data user yang sedang login pada browser saat ini.
     */
    isUser: false,

    /**
     * Menyimpan email user yang sedang login pada browser saat ini.
     */
    userEmail: false,

    /**
     * Menyimpan token user yang digunakan untuk mendapatkan data dari Backend.
     */

    token: false,

    /**
     * Ketika false maka tidak bisa mengakses route `/auth/new-password/success`
     */
    isRequestSuccess: false,
    idLevel: false,
    idGroup: false,
    idUnitKs: false
  }),

  /**
   * Mengubah value state.
   */
  getters: {
    kodeUpbjj: state => state.isUser?.user?.upbjj?.kodeUpbjj,
    unitName: state => state.isUser?.user?.group?.unit?.name
  },
  mutations: {
    SET_USER_TOKEN(state, data) {
      state.token = data
    },
    SET_CURRENT_USER(state, data) {
      state.isUser = data
      state.idLevel = data && data.id
      state.idGroup = data && data.id_group
      state.idUnitKs = data && data.id_unit_ks
    },
    SET_USER_EMAIL(state, email) {
      state.userEmail = email
    },
    SET_REQUEST_EMAIL_STATUS(state, status) {
      state.isRequestSuccess = status
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('SET_USER_TOKEN', token)
    },
    /**
     * @param {Object} data - Berisi password dan email.
     * @param {string} data.password - Password.
     * @param {string} data.email - Email.
     * @description
     * - 📌 Action ini dipanggil pada
     *   - `views/auth/Login.vue`
     * - 📝 Dipanggil saat user melakukan login pada form login.
     */
    async postLogin({ commit }, { email = '', password = '' } = {}) {
      /**
       * - Body form data untuk login
       */
      const bodyFormData = new FormData()
      bodyFormData.append('email', email)
      bodyFormData.append('password', password)
      /**
       * @description
       * -  Menjalankan api `login` dengan menggunakan axios
       */
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/login`,
        data: bodyFormData,
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(res => {
          /**
           * Menyimpan token dari response ketika login sukses.
           */
          commit('SET_USER_TOKEN', res.data.token)
          return res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })

      /**
       * - Menyimpan email dari parameter.
       * - Email tetap disimpan walaupun login gagal.
       */
      commit('SET_USER_EMAIL', email)
      return result
    },

    /**
     * @description
     * - 📌 Action ini dipanggil pada \
     *   1. `layouts/LayoutMaster.vue`
     * - 📝 Dipanggil saat user melakukan logout.
     */
    async postLogout({ commit, state }, session = true) {
      const { token } = state
      commit('progressService/DELETE_ALL_PROGRESS', null, { root: true })
      if (token && session) {
        /**
         * State token dihilangkan terlebih dahulu.
         * supaya ketika error, user akan tetap terlogout
         */
        commit('SET_USER_TOKEN', false)
        /**
         * @description
         * -  Menjalankan api `logout` dengan menggunakan axios
         */
        const result = await axios(
          {
            token: 'Bearer ' + token
          },
          {
            url: `${process.env.VUE_APP_URL_PUBLIC}/logout`,
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token
            }
          }
        )
          .then(
            res => res
            /**
             * Memastikan user tetap terlogout dalam kondisi error.
             */
            // commit('SET_CURRENT_USER', false)
            // localStorage.removeItem('user')
            // localStorage.removeItem('access_token')
            // router.push({ name: 'auth1.sign-in1' })
            // return res.data
          )
          .catch(err => err)
          .finally(() => {
            /**
             * Ketika proses selesai state `isUser` akan dikosong kan atau dibuat false. \
             * Setelah itu akan otomatis diarahkan ke halaman login.
             */
            commit('SET_CURRENT_USER', false)
            commit('SET_USER_TOKEN', false)
            localStorage.removeItem('user')
            localStorage.removeItem('access_token')
            localStorage.removeItem('vuex')
            // router.push({ name: 'dashboard.home-4' })
            router.push({ name: 'auth1.sign-in1' })
          })
        return result
      } else {
        /**
         * Memastikan user tetap terlogout dalam kondisi error.
         */
        commit('SET_CURRENT_USER', false)
        commit('SET_USER_TOKEN', false)
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
        localStorage.removeItem('vuex')
        // router.push({ name: 'auth1.sign-in1' })
        router.push({ name: 'dashboard.home-4' })
      }
    },
    /**
     * - Mendapatkan data user yang sedang login pada browser saat ini.
     * - Tidak memuat parameter apapun.
     * - dipanggil ketika memuat layout sebagai contoh `navbar`, karena navbar menampilkan data user.
     */
    async getCurrentUser({ commit, state, dispatch }) {
      const { token } = state
      /**
       * @description
       * -  Menjalankan api `logout` dengan menggunakan axios
       */
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/get_user`,
        // url: 'https://apiaksara.ut.ac.id/api/get_user',
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(res => {
          /**
           * Ketika gagal mendapatkan currentUser maka user akan otomatis ter logout.
           */
          // console.log('isUser ', res.data.user)
          if (res.data.user) {
            commit('SET_CURRENT_USER', res.data.user)
          } else {
            dispatch('postLogout', false)
          }
          /**
           * - Menyimpan ID Level user, dengan ketentuan :
           *  - 1 adalah superadmin
           *  - 2 adalah operator
           *  - 3 adalah admin
           * - Menggunakan prototype supaya value bersifat global.
           */
          // Vue.prototype.$user.idLevel = res.data.user.id
          return res.data.user
        })
        .catch(() => {
          /**
           * Ketika gagal mendapatkan currentUser maka user akan otomatis ter logout.
           */
          dispatch('postLogout', false)
          return false
        })
      return result
    },
    /**
     *
     * @param {Object} obj - Berisi object password, passwordConfirmation.
     * @param {String} obj.password - password yang user ingin ganti.
     * @param {String} obj.passwordConfirmation - konfirmasi password.
     * @description
     * - 📝 dipanggil ketika user mengganti password.
     */
    async resetPassword({ state }, { password, passwordConfirmation } = {}) {
      /**
       * Tricky karna parameter pertama wajib di isi.
       */
      // eslint-disable-next-line no-lone-blocks, no-unused-expressions
      {
        state
      }

      /**
       * mutation untuk reset password
       */
      const query = `#graphql
        mutation resetPassword(
          $password: String!
          $passwordConfirmation: String!
        ) {
          resetPassword(
            password: $password
            passwordConfirmation: $passwordConfirmation
          )
        }
      `

      const variables = { password, passwordConfirmation }
      const result = await graphqlQuery({ query, variables })

      return result
    },
    /**
     *
     * @param {Object} obj - Berisi object password, passwordConfirmation.
     * @param {String} obj.password - password yang user ingin ganti.
     * @param {String} obj.passwordConfirmation - konfirmasi password.
     * @param {token} obj.token - Berasal dari url query yang didapat dari link di email.
     * @description - 📝 Dipanggil ketika reset password namun via link dari email.
     */
    async resetPasswordEmail(
      { state },
      { password, passwordConfirmation, token } = {}
    ) {
      /**
       * Tricky karna parameter pertama wajib di isi.
       */
      // eslint-disable-next-line no-lone-blocks, no-unused-expressions
      {
        state
      }

      /**
       * mutation untuk reset password via email.
       */
      const query = `mutation {
        resetPasswordEmail(
          password: "${password}"
          passwordConfirmation: "${passwordConfirmation}"
          token: "${token}"
        )
      }
      `
      const result = await graphqlQuery({ query })
      return result
    },
    /**
     *
     * @param {String} email - email user.
     * @description
     * - 📝 Dipanggil ketika user lupa password, lalu request email untuk lupa password.
     */
    async reqEmailPassword({ commit }, email) {
      const query = `#graphql
        mutation requestResetPasswordEmail($email: String!) {
          requestResetPasswordEmail(email: $email)
        }
      `

      const variables = { email }

      const result = await graphqlQuery({ query, variables }).then(() => {
        /**
         * Set state requestEmailStatus menjadi true. \
         * tujuan nya supaya bisa mengakses halaman sukses kirim email. \
         * karena jika state tersebut bernilai false maka halaman tidak bisa diakses.
         */
        commit('SET_REQUEST_EMAIL_STATUS', true)
      })
      return result
    },
    setRequestEmailStatus({ commit }, status) {
      commit('SET_REQUEST_EMAIL_STATUS', status)
    },
    /**
     * @description
     * - Dipanggil ketika update session, \
     * setiap 50 menit sekali sesi akan diperpanjang \
     * dengan memanggil fungsi ini.
     */
    async updateSession({ commit, state }) {
      const { token } = state
      const query = `#graphql
        mutation {
          updateSession
        }
      `

      const result = await graphqlQuery({ query, token })
        .then(res => {
          commit('SET_USER_TOKEN', res.data.updateSession)
        })
        .catch(err => {
          throw new Error(err)
        })

      return result
    }
  }
}
