import Vue from 'vue'
export default {
  namespaced: true,
  state: () => ({
    listProgress: []
    // actionProgress: {
    //   regMasal: () => true
    // }
  }),
  mutations: {
    ADD_LIST_PROGRESS(state, data) {
      state.listProgress.push(data)
    },
    SET_STATUS_PROGRESS(state, data) {
      const dataIndex = state.listProgress.findIndex(
        item => item.key === data.key
      )

      Vue.set(state.listProgress, dataIndex, {
        ...state.listProgress[dataIndex],
        ...data
      })
    },
    DELETE_ALL_PROGRESS(state) {
      state.listProgress = []
    }
    // SET_ACTION_PROGRESS (state, payload) {
    //   state.actionProgress[payload.key] = payload.data
    // }
  },
  actions: {
    addListProgress({ commit }, data) {
      commit('ADD_LIST_PROGRESS', data)
    },
    setStatusProgress({ commit }, data) {
      commit('SET_STATUS_PROGRESS', data)
    },
    closeProgress({ commit }) {
      commit('DELETE_ALL_PROGRESS')
    }
    // setActionProgress ({ commit }, payload) {
    //   commit('SET_ACTION_PROGRESS', payload)
    // }
  }
}
