export const useRouterLog = ({ to, from, token, count } = {}) => {
  console.groupCollapsed(
    `%cRoute diarahkan dari %c${from.path}%c ke %c${to.path}`,
    'background: #394452; color: #ffffff; border-radius: 5px 0 0 5px; padding:4px 8px;display: inline-block;',
    'background: #394452; color: #FFCC29; padding:4px 8px;display: inline-block;',
    'background: #394452; color: #ffffff; padding:4px 8px;display: inline-block;',
    'background: #394452; color: #FFCC29; border-radius: 0 5px 5px 0; padding:4px 8px;display: inline-block;'
  )
  console.group('Route diarahkan dari')
  console.count('beforeEach ( total route ganti ): ')
  console.log('meta:', to.meta)
  console.log('count:', count)
  console.log('token: ', token)
  console.groupEnd('Route diarahkan dari')
  console.groupEnd('Route diarahkan dari')
}
