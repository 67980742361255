import '@babel/polyfill'
import Vue from 'vue'
import 'mutationobserver-shim'
import './Utils/fliter'
// import './config/firebase'
import App from './App.vue'
import router from './router'
import store from './store'
import Raphael from 'raphael/raphael'
import './plugins'
import './registerServiceWorker'
import AlgoliaComponents from 'vue-instantsearch'
import i18n from './i18n'
import './directives'

/**
 * Import Custom Global Package
 */
// Vue Toast Message
import VueToast from 'vue-toast-notification'
import '@/assets/styles/package/theme-sugar.css'
// Input Primary
import InputPrimary from '@/components/core/input/InputPrimary.vue'
// Input Datalists
import InputDatalists from '@/components/core/input/inputDatalists/src/index.js'
// vue-collapse-transition
import { CollapseTransition } from '@ivanv/vue-collapse-transition'
// Atom Spinner
// import AtomSpinner from '@/components/core/loader/AtomSpinner.vue'
// Is Loading
import IsLoading from '@/components/core/loader/IsLoading.vue'
// custom load
import IsLoad from '@/components/common/IsLoad.vue'
/**
 * Usage Custom Global Package
 */
// Vue Toast Message
Vue.use(VueToast, {
  position: 'top-right',
  duration: 3500
})
// Input Primary
Vue.component('input-primary', InputPrimary)
// Input Datalist
Vue.component('input-datalists', InputDatalists)
// vue-collapse-transition
Vue.component('collapse-transition', CollapseTransition)
// Atom Spinner
// Vue.component('atom-spinner', AtomSpinner)
// Atom Spinner
Vue.component('is-loading', IsLoading)
// custom loading
Vue.component('IsLoad', IsLoad)

global.Raphael = Raphael

Vue.use(AlgoliaComponents)

Vue.config.productionTip = false

const vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
