/**
 * Mengambil semua file js yang terdapat pada folder ini.
 */
const requireModule = require.context('.', false, /\.js$/)
const modules = {}

/**
 * Dari file  yang didapat di loop \
 * untuk dimasukan ke object `modules` \
 * dengan nama object sesuai nama file nya.
 */
requireModule.keys().forEach(fileName => {
  /**
   * Mengecualikan untuk memasukan file index ke object
   */
  if (fileName === './index.js') return

  const moduleName = fileName.replace(/\.[/]|.js|[/]/g, '')
  modules[moduleName] = requireModule(fileName).default
})

export default modules
