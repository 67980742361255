import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { useRouterLog } from '@/common/developerLog.js'

/* Layouts */
// const Layout1 = () => import('@/layouts/Layout1.vue')
const Layout2 = () => import('@/layouts/Layout2.vue')
const Layout3 = () => import('@/layouts/Layout3.vue')
const Layout4 = () => import('@/layouts/Layout4.vue')
// const Layout5 = () => import('@/layouts/Layout5.vue')
const Layout6 = () => import('@/layouts/Layout6.vue')
const Default = () => import('@/layouts/BlankLayout')
const AuthLayout = () => import('@/layouts/AuthLayouts/AuthLayout')
/* Dashboards View */
const Dashboard = () => import('@/views/Dashboards/Dashboard6')
// const Dashboard1 = () => import('@/views/Dashboards/Dashboard1.vue')
// const Dashboard2 = () => import('@/views/Dashboards/Dashboard2.vue')
const Dashboard3 = () => import('@/views/Dashboards/Dashboard3')
const Dashboard4 = () => import('@/views/Dashboards/Dashboard4')
// const Dashboard5 = () => import('@/views/Dashboards/Dashboard5')
/* UIElements View */
const UiAlerts = () => import('@/views/core/UiAlerts.vue')
const UiButtons = () => import('@/views/core/UiButtons.vue')
const UiBadges = () => import('@/views/core/UiBadges.vue')
const UiBreadcrumb = () => import('@/views/core/UiBreadcrumb.vue')
const UiColors = () => import('@/views/core/UiColors.vue')
const UiTypography = () => import('@/views/core/UiTypography.vue')
const UiCards = () => import('@/views/core/UiCards.vue')
const UiCarousel = () => import('@/views/core/UiCarousel.vue')
const UiEmbedVideo = () => import('@/views/core/UiEmbedVideo.vue')
const UiGrid = () => import('@/views/core/UiGrid.vue')
const UiModal = () => import('@/views/core/UiModal.vue')
const UiListGroup = () => import('@/views/core/UiListGroup.vue')
const UiImages = () => import('@/views/core/UiImages.vue')
const UiMediaObject = () => import('@/views/core/UiMediaObject.vue')
const UiTooltips = () => import('@/views/core/UiTooltips.vue')
const UiPopovers = () => import('@/views/core/UiPopovers.vue')
const UiNotifications = () => import('@/views/core/UiNotifications.vue')
const UiTabs = () => import('@/views/core/UiTabs.vue')
const UiPagination = () => import('@/views/core/UiPagination.vue')
const UiProgressBars = () => import('@/views/core/UiProgressBars.vue')
/* Authentic View */
const SignIn1 = () => import('@/views/AuthPages/Default/SignIn1')
const SignUp1 = () => import('@/views/AuthPages/Default/SignUp1')
const RecoverPassword1 = () =>
  import('@/views/AuthPages/Default/RecoverPassword1')
const LockScreen1 = () => import('@/views/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('@/views/AuthPages/Default/ConfirmMail1')
const Callback = () => import('@/views/AuthPages/Default/Callback')
/* Extra Pages */
const ErrorPage = () => import('@/views/Pages/ErrorPage')
const ComingSoon = () => import('@/views/Pages/ComingSoon')
const Maintenance = () => import('@/views/Pages/Maintenance')
const TimeLine = () => import('@/views/Pages/TimeLines')
const Pricing = () => import('@/views/Pages/Pricing')
const Pricing1 = () => import('@/views/Pages/Pricing1')
const BlankPage = () => import('@/views/Pages/BlankPage')
const FAQ = () => import('@/views/Pages/FAQ')
const Invoice = () => import('@/views/Pages/Invoice')
/* Apps Views */
const EmailListing = () => import('@/views/Apps/Email/EmailListing')
const EmailCompose = () => import('@/views/Apps/Email/EmailCompose')
const Calendar = () => import('@/views/Apps/Calendar/Calendar')
const ECommerceListing = () => import('@/views/Apps/Ecommerce/Listing')
const Checkout = () => import('@/views/Apps/Ecommerce/Checkout')
const Wishlist = () => import('@/views/Apps/Ecommerce/Wishlist')
const EcommerceDetail = () => import('@/views/Apps/Ecommerce/Detail')
const ChatIndex = () => import('@/views/Apps/Chat/Index')
const ProjectBoard = () => import('@/views/Apps/ProjectManagement/ProjectBoard')
/* Icon Views */
const IconDripicons = () => import('@/views/Icons/IconDripicons')
const IconFontawesome5 = () => import('@/views/Icons/IconFontawesome5')
const IconLineAwesome = () => import('@/views/Icons/IconLineAwesome')
const IconRemixicon = () => import('@/views/Icons/IconRemixicon')
const IconUnicons = () => import('@/views/Icons/IconUnicons')
/* Tables Views */
const TablesBasic = () => import('@/views/Tables/TablesBasic')
const EditableTable = () => import('@/views/Tables/EditableTable')
/* Charts View */
const ApexCharts = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "ApexChart" */ '@/views/Charts/ApexCharts'
  )
const AmCharts = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "AmCharts" */ '@/views/Charts/AmCharts'
  )
const HighCharts = () =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "HighCharts" */ '@/views/Charts/HighCharts'
  )
/* Google Map */
const GoogleMaps = () => import('@/views/Maps/GoogleMaps')
/* Form View */
const FormLayout = () => import('@/views/Forms/FormLayout')
const FormValidates = () => import('@/views/Forms/FormValidates')
const FormSwitches = () => import('@/views/Forms/FormSwitches')
const FormRadios = () => import('@/views/Forms/FormRadios')
const FormCheckboxes = () => import('@/views/Forms/FormCheckboxes')
/* User View */
const Profile = () => import('@/views/User/Profile')
const ProfileEdit = () => import('@/views/User/ProfileEdit')
const AddUser = () => import('@/views/User/AddUser')
const UserList = () => import('@/views/User/UserList')
const AccountSetting = () => import('@/views/User/AccountSetting')
const PrivacySetting = () => import('@/views/User/PrivacySetting')
/* Apps View */
const SocialApp = () => import('@/views/Apps/Social/SocialApp')
const TodoListing = () => import('@/views/Apps/Todo/TodoListing')
/* Plugins Views */
const DatepickerDemo = () => import('@/views/Plugins/DatepickerDemo')
const SelectDemo = () => import('@/views/Plugins/SelectDemo')
const DragDropDemo = () => import('@/views/Plugins/DragDropDemo')
const AppTreeView = () => import('@/views/Plugins/AppTreeView')
/* Master */
const JenisPks = () => import('@/views/Pages/Master/JenisPks')
const Mitra = () => import('@/views/Pages/Master/Mitra')
const GroupMitra = () => import('@/views/Pages/Master/GroupMitra')
const KategoriPks = () => import('@/views/Pages/Master/KategoriPks')
const SubKategoriPks = () => import('@/views/Pages/Master/SubKategoriPks')
/* Kerjasama */
const JenisDokumen = () => import('@/views/Pages/Kerjasama/JenisDokumen/Main')
/* Kerjasama - PKS */
const Pks = () => import('@/views/Pages/Kerjasama/JenisDokumen/Pks/Main')
const FormAddPks = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Pks/FormTambah')
const FormDetailPks = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Pks/FormDetail')
const FormEditPks = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Pks/FormEdit')
/* Kerjasama - MoU */
const Mou = () => import('@/views/Pages/Kerjasama/JenisDokumen/Mou/Main')
const FormAddMou = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Mou/FormTambah')
const FormDetailMou = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Mou/FormDetail')
const FormEditMou = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Mou/FormEdit')
/* Kerjasama - Kontrak */
const Kontrak = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Kontrak/Main')
const FormAddKontrak = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Kontrak/FormTambah')
const FormDetailKontrak = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Kontrak/FormDetail')
const FormEditKontrak = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Kontrak/FormEdit')
/* Kerjasama - IA */
const IA = () => import('@/views/Pages/Kerjasama/JenisDokumen/Ia/Main')
const FormAddIA = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Ia/FormTambah')
const FormDetailIA = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Ia/FormDetail')
const FormEditIA = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Ia/FormEdit')
/* Kerjasama - SK */
const SK = () => import('@/views/Pages/Kerjasama/JenisDokumen/Sk/Main')
const FormAddSK = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Sk/FormTambah')
const FormDetailSK = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Sk/FormDetail')
const FormEditSK = () =>
  import('@/views/Pages/Kerjasama/JenisDokumen/Sk/FormEdit')
/* Kerjasama - Entry Beasiswa */
const EntryBeasiswa = () => import('@/views/Pages/Kerjasama/EntryBeasiswa/Main')
const ImportMahasiswaBeasiswa = () =>
  import('@/views/Pages/Kerjasama/ImportMahasiswaBeasiswa/Main')
/** Kerjasama - Surat Tagihan Biaya Pendidikan */
const SuratTagihanBiayaPendidikan = () =>
  import('@/views/Pages/Kerjasama/SuratTagihanBiayaPendidikan/Main')
/* Kerjasama - Dokumentasi SK */
const DokumentasiSk = () => import('@/views/Pages/Kerjasama/DokumentasiSk/Main')
/* Kerjasama - Serbaguna Billing */
const UbahStatusBillingSatuan = () =>
  import('@/views/Pages/Kerjasama/SerbagunaBilling/UbahStatusBillingSatuan')
const UbahStatusBillingMassal = () =>
  import('@/views/Pages/Kerjasama/SerbagunaBilling/UbahStatusBillingMassal')
const FlaggingBilingAdmisiMouSatuan = () =>
  import('@/views/Pages/Kerjasama/FlaggingBillingAdmisiMou/FlaggingSatuan')
const FlaggingBilingAdmisiMouMassal = () =>
  import('@/views/Pages/Kerjasama/FlaggingBillingAdmisiMou/FlaggingMassal')
/* Laporan */
const PerJenisKerjasama = () =>
  import('@/views/Pages/Laporan/PerJenisKerjasama/Main')
const PerUpbjj = () => import('@/views/Pages/Laporan/PerUpbjj/Main')
const LaporanKerjasama = () => import('@/views/Pages/Laporan/Kerjasama/Main')
const DetailLaporanKerjasama = () =>
  import('@/views/Pages/Laporan/Kerjasama/Detail')
const LaporanKontrak = () => import('@/views/Pages/Laporan/Kontrak/Main')
const LaporanMasaBerlakuPks = () =>
  import('@/views/Pages/Laporan/MasaBerlakuPks/Main')
const InformasiDokumenPks = () =>
  import('@/views/Pages/Laporan/InformasiDokumenPks/Main')
/* Menu Khusus */
const AktivasiBillingBeasiswaSatuan = () =>
  import(
    '@/views/Pages/MenuKhusus/AktivasiBillingBeasiswa/AktivasiBillingBeasiswaSatuan'
  )
const AktivasiBillingBeasiswaMassal = () =>
  import(
    '@/views/Pages/MenuKhusus/AktivasiBillingBeasiswa/AktivasiBillingBeasiswaMassal'
  )
const VerifikasiDokumen = () =>
  import('@/views/Pages/MenuKhusus/VerifikasiDokumen/Main')
const PembatalanVerifikasi = () =>
  import('@/views/Pages/MenuKhusus/PembatalanVerifikasi/Main')
const ManajemenUser = () =>
  import('@/views/Pages/MenuKhusus/ManajemenUser/Main')

Vue.use(VueRouter)

const coreChildRoute = prop => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { auth: true, name: 'Colors' },
    component: UiColors
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { auth: true, name: 'Typography' },
    component: UiTypography
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { auth: true, name: 'Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { auth: true, name: 'Button' },
    component: UiButtons
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { auth: true, name: 'Badges' },
    component: UiBadges
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { auth: true, name: 'Card' },
    component: UiCards
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { auth: true, name: 'Carousel' },
    component: UiCarousel
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { auth: true, name: 'Grid' },
    component: UiGrid
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { auth: true, name: 'Embed Video' },
    component: UiEmbedVideo
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { auth: true, name: 'Model' },
    component: UiModal
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { auth: true, name: 'List Group' },
    component: UiListGroup
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { auth: true, name: 'Image' },
    component: UiImages
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { auth: true, name: 'Media Object' },
    component: UiMediaObject
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { auth: true, name: 'Paginations' },
    component: UiPagination
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { auth: true, name: 'Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { auth: true, name: 'Tooltip' },
    component: UiTooltips
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { auth: true, name: 'Popover' },
    component: UiPopovers
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { auth: true, name: 'Notification' },
    component: UiNotifications
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { auth: true, name: 'Tabs' },
    component: UiTabs
  }
]
const formChildRoute = prop => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { auth: true, name: 'Layout' },
    component: FormLayout
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { auth: true, name: 'Validate' },
    component: FormValidates
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { auth: true, name: 'Switch' },
    component: FormSwitches
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { auth: true, name: 'Radio' },
    component: FormRadios
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { auth: true, name: 'Checkbox' },
    component: FormCheckboxes
  }
]

const tableChildRoute = prop => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { auth: true, name: 'Basic' },
    component: TablesBasic
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { auth: true, name: 'Editable' },
    component: EditableTable
  }
]

const iconChildRoute = prop => [
  {
    path: 'dripicons',
    name: prop + '.dripicons',
    meta: { auth: true, name: 'Dripicons' },
    component: IconDripicons
  },
  {
    path: 'fontawesome-5',
    name: prop + '.fontawesome-5',
    meta: { auth: true, name: 'Font Awsome' },
    component: IconFontawesome5
  },
  {
    path: 'lineawesome',
    name: prop + '.lineawesome',
    meta: { auth: true, name: 'Line Awsome' },
    component: IconLineAwesome
  },
  {
    path: 'remixicon',
    name: prop + '.remixicon',
    meta: { auth: true, name: 'Remixicon' },
    component: IconRemixicon
  },
  {
    path: 'unicons',
    name: prop + '.unicons',
    meta: { auth: true, name: 'Unicon' },
    component: IconUnicons
  }
]

const chartChildRoutes = prop => [
  {
    path: 'apex-charts',
    name: prop + '.apex',
    meta: { auth: true, name: 'Apex Chart' },
    component: ApexCharts
  },
  {
    path: 'am-charts',
    name: prop + '.am',
    meta: { auth: true, name: 'Am Chart' },
    component: AmCharts
  },
  {
    path: 'high-charts',
    name: prop + '.high',
    meta: { auth: true, name: 'High Chart' },
    component: HighCharts
  }
]

const appChildRoute = prop => [
  {
    path: 'calendar',
    name: prop + '.calendar',
    meta: { auth: true, name: 'Calendar' },
    component: Calendar
  },
  {
    path: 'chat',
    name: prop + '.chat',
    meta: { auth: true, name: 'Chat' },
    component: ChatIndex
  },
  {
    path: 'email',
    name: prop + '.email',
    meta: { auth: true, name: 'Email' },
    component: EmailListing
  },
  {
    path: 'email-compose',
    name: prop + '.email.compose',
    meta: { auth: true, name: 'New Email' },
    component: EmailCompose
  },
  {
    path: 'e-commerce/listing',
    name: prop + '.e-commerce.index',
    meta: { auth: true, name: 'Product list' },
    component: ECommerceListing
  },
  {
    path: 'e-commerce/detail',
    name: prop + '.e-commerce.detail',
    meta: { auth: true, name: 'Product Detail' },
    component: EcommerceDetail
  },
  {
    path: 'e-commerce/checkout',
    name: prop + '.e-commerce.cart',
    meta: { auth: true, name: 'Checkout' },
    component: Checkout
  },
  {
    path: 'e-commerce/wish-list',
    name: prop + '.e-commerce.wishlist',
    meta: { auth: true, name: 'Wishlist' },
    component: Wishlist
  },
  {
    path: 'project-management',
    name: prop + '.project.management',
    meta: { auth: true, name: 'Project Management' },
    component: ProjectBoard
  },
  {
    path: 'todo',
    name: prop + '.todo',
    meta: { auth: true, name: 'Todo' },
    component: TodoListing
  },
  {
    path: 'treeview',
    name: prop + '.treeview',
    meta: { auth: true, name: 'Tree View' },
    component: AppTreeView
  }
]

const authChildRoutes = prop => [
  {
    path: 'sign-in',
    name: prop + '.sign-in1',
    meta: { auth: false },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { auth: false },
    component: SignUp1
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { auth: false },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    component: ConfirmMail1
  }
]

const mapChildRoute = prop => [
  {
    path: 'google-maps',
    name: prop + '.google',
    meta: { auth: true, name: 'Google map' },
    component: GoogleMaps
  }
]

const defaultlayout = prop => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { auth: true, name: 'Timeline' },
    component: TimeLine
  },
  {
    path: 'invoice',
    name: prop + '.invoice',
    meta: { auth: true, name: 'Invoice' },
    component: Invoice
  },
  {
    path: 'blank-page',
    name: prop + '.blank-page',
    meta: { auth: true, name: 'Blank Page' },
    component: BlankPage
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { auth: true, name: 'Pricing' },
    component: Pricing
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { auth: true, name: 'Pricing 1' },
    component: Pricing1
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true, name: 'Faq' },
    component: FAQ
  }
]

const pagesChildRoutes = prop => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { auth: true },
    component: ErrorPage
  },
  {
    path: 'coming-soon',
    name: prop + '.coming-soon',
    meta: { auth: true },
    component: ComingSoon
  },
  {
    path: 'maintenance',
    name: prop + '.maintenance',
    meta: { auth: true },
    component: Maintenance
  }
]
const userChildRoute = prop => [
  {
    path: 'profile',
    name: prop + '.profile',
    meta: { auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'profile-edit/:id',
    name: prop + '.edit',
    meta: { auth: true, name: 'Edit Profile' },
    component: ProfileEdit
  },
  {
    path: 'add-user',
    name: prop + '.add',
    meta: { auth: true, name: 'Add Profile' },
    component: AddUser
  },
  {
    path: 'user-list',
    name: prop + '.list',
    meta: { auth: true, name: 'User List' },
    component: UserList
  },
  {
    path: 'account-setting',
    name: prop + '.account-setting',
    meta: { auth: true, name: 'Account Setting' },
    component: AccountSetting
  },
  {
    path: 'privacy-setting',
    name: prop + '.privacy-setting',
    meta: { auth: true, name: 'Privacy Setting' },
    component: PrivacySetting
  }
]
const socialChildRoute = prop => [
  {
    path: 'social',
    name: prop + '.list',
    meta: { auth: true, name: 'Social App' },
    component: SocialApp
  }
]

const pluginsChildRoute = prop => [
  {
    path: 'datepicker',
    name: prop + '.datepicker',
    meta: { auth: true, name: 'Datepicker' },
    component: DatepickerDemo
  },
  {
    path: 'select',
    name: prop + '.select',
    meta: { auth: true, name: 'Select' },
    component: SelectDemo
  },
  {
    path: 'draggable',
    name: prop + '.draggable',
    meta: { auth: true, name: 'Draggable' },
    component: DragDropDemo
  }
]
const routes = [
  {
    path: '',
    component: Layout4,
    children: [
      {
        path: '',
        name: 'dashboard.home-4',
        meta: { auth: false, name: 'Home' },
        component: Dashboard4
      }
    ]
  },
  {
    path: '',
    name: 'dashboard',
    component: Layout6,
    meta: { auth: true },
    children: [
      {
        path: 'dashboard',
        name: 'sidebar.dashboard',
        meta: { auth: true, name: 'Dashboard' },
        component: Dashboard
      }
    ]
  },
  {
    path: '/master',
    component: Layout6,
    meta: { auth: true },
    children: [
      {
        path: 'jenis-pks',
        name: 'master.jenis-pks',
        meta: { auth: true, name: 'Jenis PKS' },
        component: JenisPks
      },
      {
        path: 'mitra',
        name: 'master.mitra',
        meta: { auth: true, name: 'Mitra' },
        component: Mitra
      },
      {
        path: 'group-mitra',
        name: 'master.group-mitra',
        meta: { auth: true, name: 'Mitra' },
        component: GroupMitra
      },
      {
        path: 'kategori-pks',
        name: 'master.kategori-pks',
        meta: { auth: true, name: 'Kategori PKS' },
        component: KategoriPks
      },
      {
        path: 'sub-kategori-pks',
        name: 'master.sub-kategori-pks',
        meta: { auth: true, name: 'Sub Kategori PKS' },
        component: SubKategoriPks
      }
    ]
  },
  {
    path: '/kerjasama',
    component: Layout6,
    meta: { auth: true },
    children: [
      {
        path: 'jenisDokumen',
        name: 'kerjasama.jenisDokumen',
        meta: { auth: true, name: 'Jenis Dokumen' },
        component: JenisDokumen
      },
      // PKS
      {
        path: 'pks',
        name: 'kerjasama.pks',
        meta: { auth: true, name: 'PKS' },
        component: Pks
      },
      {
        path: 'pks/tambah',
        name: 'kerjasama.pks.tambah',
        meta: { auth: true, name: 'Form Add PKS' },
        component: FormAddPks
      },
      {
        path: 'pks/detail',
        name: 'kerjasama.pks.detail',
        meta: { auth: true, name: 'Form Detail PKS' },
        component: FormDetailPks
      },
      {
        path: 'pks/edit',
        name: 'kerjasama.pks.edit',
        meta: { auth: true, name: 'Form Edit PKS' },
        component: FormEditPks
      },
      // MoU
      {
        path: 'mou',
        name: 'kerjasama.mou',
        meta: { auth: true, name: 'MoU' },
        component: Mou
      },
      {
        path: 'mou/tambah',
        name: 'kerjasama.mou.tambah',
        meta: { auth: true, name: 'Form Add MoU' },
        component: FormAddMou
      },
      {
        path: 'mou/detail',
        name: 'kerjasama.mou.detail',
        meta: { auth: true, name: 'Form Detail MoU' },
        component: FormDetailMou
      },
      {
        path: 'mou/edit',
        name: 'kerjasama.mou.edit',
        meta: { auth: true, name: 'Form Edit MoU' },
        component: FormEditMou
      },
      // Kontrak
      {
        path: 'kontrak',
        name: 'kerjasama.kontrak',
        meta: { auth: true, name: 'Kontrak' },
        component: Kontrak
      },
      {
        path: 'kontrak/tambah',
        name: 'kerjasama.kontrak.tambah',
        meta: { auth: true, name: 'Form Add Kontrak' },
        component: FormAddKontrak
      },
      {
        path: 'kontrak/detail',
        name: 'kerjasama.kontrak.detail',
        meta: { auth: true, name: 'Form Detail Kontrak' },
        component: FormDetailKontrak
      },
      {
        path: 'kontrak/edit',
        name: 'kerjasama.kontrak.edit',
        meta: { auth: true, name: 'Form Edit Kontrak' },
        component: FormEditKontrak
      },
      // IA
      {
        path: 'ia',
        name: 'kerjasama.ia',
        meta: { auth: true, name: 'ia' },
        component: IA
      },
      {
        path: 'ia/tambah',
        name: 'kerjasama.ia.tambah',
        meta: { auth: true, name: 'Form Add IA' },
        component: FormAddIA
      },
      {
        path: 'ia/detail',
        name: 'kerjasama.ia.detail',
        meta: { auth: true, name: 'Form Detail IA' },
        component: FormDetailIA
      },
      {
        path: 'ia/edit',
        name: 'kerjasama.ia.edit',
        meta: { auth: true, name: 'Form Edit IA' },
        component: FormEditIA
      },
      // SK
      {
        path: 'sk',
        name: 'kerjasama.sk',
        meta: { auth: true, name: 'sk' },
        component: SK
      },
      {
        path: 'sk/tambah',
        name: 'kerjasama.sk.tambah',
        meta: { auth: true, name: 'Form Add SK' },
        component: FormAddSK
      },
      {
        path: 'sk/detail',
        name: 'kerjasama.sk.detail',
        meta: { auth: true, name: 'Form Detail SK' },
        component: FormDetailSK
      },
      {
        path: 'sk/edit',
        name: 'kerjasama.sk.edit',
        meta: { auth: true, name: 'Form Edit SK' },
        component: FormEditSK
      },
      // Others
      {
        path: 'entry-beasiswa',
        name: 'kerjasama.entryBeasiswa',
        meta: { auth: true, name: 'Entry Beasiswa' },
        component: EntryBeasiswa
      },
      {
        path: 'import-mahasiswa-beasiswa',
        name: 'kerjasama.importMahasiswaBeasiswa',
        meta: { auth: true, name: 'Import Mahasiswa Beasiswa' },
        component: ImportMahasiswaBeasiswa
      },
      {
        path: 'surat-tagihan-biaya-pendidikan',
        name: 'kerjasama.suratTagihanBiayaPendidikan',
        meta: { auth: true, name: 'Surat Tagihan Biaya Pendidikan' },
        component: SuratTagihanBiayaPendidikan
      },
      {
        path: 'dokumentasi-sk',
        name: 'kerjasama.dokumentasiSk',
        meta: { auth: true, name: 'Dokumentasi SK' },
        component: DokumentasiSk
      },
      {
        path: 'ubah-status-billing-satuan',
        name: 'kerjasama.ubahStatusBillingSatuan',
        meta: { auth: true, name: 'Ubah Status Billing Satuan' },
        component: UbahStatusBillingSatuan
      },
      {
        path: 'ubah-status-billing-massal',
        name: 'kerjasama.ubahStatusBillingMassal',
        meta: { auth: true, name: 'Ubah Status Billing Massal' },
        component: UbahStatusBillingMassal
      },
      {
        path: 'flagging-biling-admisi-mou-satuan',
        name: 'kerjasama.flaggingMouSatuan',
        meta: { auth: true, name: 'Flagging Satuan' },
        component: FlaggingBilingAdmisiMouSatuan
      },
      {
        path: 'flagging-biling-admisi-mou-massal',
        name: 'kerjasama.flaggingMouMassal',
        meta: { auth: true, name: 'Flagging Massal' },
        component: FlaggingBilingAdmisiMouMassal
      }
    ]
  },
  {
    path: '/laporan',
    component: Layout6,
    meta: { auth: true },
    children: [
      {
        path: 'kerjasama',
        name: 'laporan.kerjasama',
        meta: { auth: true, name: 'Laporan Kerjasama' },
        component: LaporanKerjasama
      },
      {
        path: 'kerjasama/detail',
        name: 'laporan.kerjasama.detail',
        meta: { auth: true, name: 'Detail Laporan Kerjasama' },
        component: DetailLaporanKerjasama
      },
      {
        path: 'per-jenis-kerjasama',
        name: 'laporan.perJenisKerjasama',
        meta: { auth: true, name: 'PKS Per Jenis Kerjasama' },
        component: PerJenisKerjasama
      },
      {
        path: 'ia/detail',
        name: 'laporan.ia.detail',
        meta: { auth: true, name: 'Form Detail IA' },
        component: FormDetailIA
      },
      {
        path: 'per-upbjj',
        name: 'laporan.perUpbjj',
        meta: { auth: true, name: 'PKS Per UPBJJ' },
        component: PerUpbjj
      },
      {
        path: 'kontrak',
        name: 'laporan.kontrak',
        meta: { auth: true, name: 'Kontrak' },
        component: LaporanKontrak
      },
      {
        path: 'masaBerlakuPks',
        name: 'laporan.masaBerlakuPks',
        meta: { auth: true, name: 'Masa Berlaku PKS' },
        component: LaporanMasaBerlakuPks
      },
      {
        path: 'informasi-dokumen-pks',
        name: 'laporan.informasiDokumenPks',
        meta: { auth: true, name: 'Informasi Dokumen PKS' },
        component: InformasiDokumenPks
      }
    ]
  },
  {
    path: '/menu-khusus',
    component: Layout6,
    meta: { auth: true },
    children: [
      {
        path: 'aktivasi-billing-beasiswa',
        name: 'menuKhusus.aktivasiSatuan',
        meta: { auth: true, name: 'Aktivasi Satuan' },
        component: AktivasiBillingBeasiswaSatuan
      },
      {
        path: 'aktivasi-billing-beasiswa-massal',
        name: 'menuKhusus.aktivasiMassal',
        meta: { auth: true, name: 'Aktivasi Massal' },
        component: AktivasiBillingBeasiswaMassal
      },
      {
        path: 'verifikasi-dokumen',
        name: 'menuKhusus.verifikasiDokumen',
        meta: { auth: true, name: 'Verifikasi Dokumen' },
        component: VerifikasiDokumen
      },
      {
        path: 'pembatalan-verifikasi',
        name: 'menuKhusus.pembatalanVerifikasi',
        meta: { auth: true, name: 'Pembatalan Verifikasi' },
        component: PembatalanVerifikasi
      },
      {
        path: 'manajemen-user',
        name: 'menuKhusus.manajemenUser',
        meta: { auth: true, name: 'Manajemen User' },
        component: ManajemenUser
      }
    ]
  },
  // {
  //   path: '',
  //   // name: 'dashboard',
  //   component: Layout1,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       path: 'home-1',
  //       name: 'dashboard.home-1',
  //       meta: { auth: true, name: 'Home 1' },
  //       component: Dashboard1
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   // name: 'dashboard',
  //   component: Layout2,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       path: 'home-2',
  //       name: 'dashboard.home-2',
  //       meta: { auth: true, name: 'Home 2' },
  //       component: Dashboard2
  //     }
  //   ]
  // },
  {
    path: '/menu-design',
    // name: 'dashboard',
    component: Layout3,
    meta: { auth: true },
    children: [
      {
        path: 'home-3',
        name: 'dashboard.home-3',
        meta: { auth: true, name: 'Home 3' },
        component: Dashboard3
      }
    ]
  },
  // {
  //   path: '/menu-design',
  //   name: 'dashboard',
  //   component: Layout4,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       path: 'home-4',
  //       name: 'dashboard.home-4',
  //       meta: { auth: true, name: 'Home 4' },
  //       component: Dashboard4
  //     }
  //   ]
  // },
  // {
  //   path: '',
  //   // name: 'dashboard',
  //   component: Layout5,
  //   meta: { auth: true },
  //   children: [
  //     {
  //       path: 'home-5',
  //       name: 'dashboard.home-5',
  //       meta: { auth: true, name: 'Home 5' },
  //       component: Dashboard5
  //     }
  //   ]
  // },
  {
    path: '/core',
    name: 'core',
    component: Layout2,
    meta: { auth: true },
    children: coreChildRoute('core')
  },
  {
    path: '/table',
    name: 'table',
    component: Layout2,
    meta: { auth: true },
    children: tableChildRoute('table')
  },
  {
    path: '/chart',
    name: 'chart',
    component: Layout2,
    meta: { auth: true },
    children: chartChildRoutes('chart')
  },
  {
    path: '/icon',
    name: 'icon',
    component: Layout2,
    meta: { auth: true },
    children: iconChildRoute('icon')
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default')
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout2,
    meta: { auth: true },
    children: defaultlayout('extra-pages')
  },
  {
    path: '/app',
    name: 'app',
    component: Layout2,
    meta: { auth: true },
    children: appChildRoute('app')
  },
  {
    path: '/user',
    name: 'user',
    component: Layout2,
    meta: { auth: true },
    children: userChildRoute('user')
  },
  {
    path: '/social',
    name: 'social',
    component: Layout2,
    meta: { auth: true },
    children: socialChildRoute('social')
  },
  {
    path: '/map',
    name: 'map',
    component: Layout2,
    meta: { auth: true },
    children: mapChildRoute('map')
  },
  {
    path: '/form',
    name: 'form',
    component: Layout2,
    meta: { auth: true },
    children: formChildRoute('form')
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: Layout2,
    meta: { auth: true },
    children: pluginsChildRoute('plugins')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

let count = 0

router.beforeEach(async (to, from, next) => {
  // const publicPages = [
  //   '/auth/sign-in',
  //   '/auth/sign-up1',
  //   '/dark/auth/sign-in',
  //   '/dark/auth/sign-up1'
  // ]
  // if (publicPages.includes(to.path)) {
  // localStorage.removeItem('user')
  // localStorage.removeItem('access_token')
  // }
  /** authRequired sama aja dengan to.meta.auth (check route with auth required) */
  // const authRequired = !publicPages.includes(to.path)
  // const loggedIn = localStorage.getItem('user')
  // const tokenIn = localStorage.getItem('access_token')
  const token = store.state.authService.token
  useRouterLog({ to, from, token, count })
  // if (to.meta.auth) {
  // if (authRequired && loggedIn === null) {
  //   return next('/auth/sign-in')
  // } else if (to.name === 'dashboard' || to.name === 'dashboard.home-4') {
  //   return next()
  // }
  // console.log('to ', to)
  // console.log('from ', from)
  // console.log('authRequired ', authRequired)
  // console.log('loggedIn ', loggedIn)
  // console.log('tokenIn ', tokenIn)
  // console.log('meta ', to.meta.auth)
  // console.log('path ', to.path)
  // console.log('base ', process.env.VUE_APP_BASE_URL)
  // console.log('Z ', authRequired && loggedIn)
  // console.log('ZA ', loggedIn && tokenIn)

  //   if (authRequired && loggedIn === null) {
  //     Vue.$toast.error('Sesi telah habis.')
  //     return next('/auth/sign-in')
  //   } else if (loggedIn && tokenIn === null) {
  //     Vue.$toast.error('Sesi telah habis.')
  //     return next('/auth/sign-in')
  //   } else if (to.name === 'dashboard' || to.name === 'dashboard.home-4') {
  //     return next('/dashboard')
  //   }
  // }
  count++
  const session =
    count <= 1 ? store.dispatch('authService/getCurrentUser') : true
  if (token) {
    // console.log('LOGIN 1')
    // console.log('session: ', session)
    // console.log('login: ', loggedIn)
    // console.log('token: ', tokenIn)
    // console.log('meta ', to.meta.auth)
    // console.log('session ', session)
    if (session) {
      // console.log('LOGIN 2')
      if (to.meta.auth) {
        // console.log('LOGIN 3')
        return next()
      }
      return next()
    } else {
      // console.log('LOGIN 4')
      Vue.$toast.error('Sesi telah habis.')
      return next()
    }
  } else {
    // console.log('login else: ', loggedIn)
    // console.log('token else: ', tokenIn)
    if (to.meta.auth) {
      // console.log('LOGIN 5')
      // Vue.$toast.error('Sesi telah habis.')
      return next({ name: 'auth1.sign-in1' })
    }
    // console.log('LOGIN 6')
    next()
  }
})

export default router
