<template>
  <div
    class="input__primary text-left"
    :class="{
      'text-danger': error
    }"
  >
    <label v-if="label || $slots.label" class="block pb-2" :for="id"
      >{{ label }}
      <span class="text-danger" v-if="required && label">*</span>
      <slot name="label"></slot>
    </label>
    <div
      v-if="isDetail"
      class="
        form-control
        bg-light
        block
        w-full
        transition-all
        rounded-md
        text-base
        cursor-not-allowed
        h-auto
      "
    >
      {{ inputVal || '-' }}
    </div>
    <div
      v-else-if="isDetailPrepend"
      class="input-group transition-all rounded-md cursor-not-allowed"
    >
      <div class="input-group-prepend bg-light border rounded-left">
        <span class="input-group-text">{{ placeholder || '-' }}</span>
      </div>
      <div class="form-control bg-light block w-full text-base">
        {{ inputVal || '-' }}
      </div>
    </div>
    <textarea
      :class="
        `form-control block w-full transition-all outline-none rounded-md`
      "
      :type="type"
      :id="id"
      v-else-if="type == 'textarea'"
      :placeholder="placeholder"
      :rows="rows"
      :required="required"
      :disabled="disabled"
      :name="name"
      v-on:input="inputVal = $event"
      :value="inputVal"
    />
    <div
      v-else-if="isPrepend"
      class="input-group transition-all rounded-md cursor-not-allowed"
    >
      <div class="input-group-prepend bg-light border rounded-left">
        <span class="input-group-text">{{ placeholder || '-' }}</span>
      </div>
      <input
        :class="`form-control block w-full text-base`"
        :type="type === 'email' || type === 'number' ? 'text' : type"
        :id="id"
        min="0"
        :placeholder="placeholder"
        @keydown="handleKey($event)"
        :required="required"
        :disabled="disabled"
        :name="name"
        v-on:input="inputVal = $event"
        :value="inputVal"
      />
    </div>
    <input
      v-else
      :class="`form-control block w-full transition-all rounded-md text-base`"
      :type="type === 'email' || type === 'number' ? 'text' : type"
      :id="id"
      min="0"
      :placeholder="placeholder"
      @keydown="handleKey($event)"
      :required="required"
      :disabled="disabled"
      :name="name"
      v-on:input="inputVal = $event"
      :value="inputVal"
    />
  </div>
</template>

<script>
export default {
  props: {
    /**
     * ID Wajib di isi, supaya ketika label di klik bisa otomatis focus input
     */
    id: {
      type: [String, Number]
    },
    /**
     * Tulisan yang ada diatas input persis.
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Text yang akan tampil apabila input kosong.
     */
    placeholder: {
      type: [String, Boolean],
      default: ''
    },
    /**
     * Type inputan, email, number, textarea
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * Rows inputan untuk lebar textarea
     */
    rows: {
      type: String,
      default: 'text'
    },
    /**
     * ketika benar maka muncul tanda bintang
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * ketika benar maka input jadi disable dan tidak bisa untuk input
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Lebih baik gunakan v-model daripada value
     */
    value: {
      type: [Number, String, Array, Boolean, Object],
      default: ''
    },
    /**
     * Validasi panjang karakter
     */
    maxLength: {
      type: [String, Number],
      default: ''
    },
    minLength: {
      type: [String, Number],
      default: 0
    },
    /**
     * Apabila benar ketika menginput huruf akan otomatis huruf besar
     */
    upperCase: {
      type: Boolean,
      default: false
    },
    /**
     * Apabila benar ketika menginput huruf akan otomatis huruf kecil
     */
    lowerCase: {
      type: Boolean,
      default: false
    },
    /**
     * Apabila benar ketika menginput huruf akan otomatis memblok spasi
     */
    blockSpace: {
      type: Boolean,
      default: false
    },
    /**
     * Ketika benar, input terdapat prepend sesuai placeholder
     */
    isPrepend: {
      type: Boolean,
      default: false
    },
    /**
     * Ketika benar, input menjadi mode detail, (tidak bisa input)
     */
    isDetail: {
      type: Boolean,
      default: false
    },
    /**
     * Ketika benar, input terdapat prepend sesuai placeholder dan menjadi mode detail, (tidak bisa input)
     */
    isDetailPrepend: {
      type: Boolean,
      default: false
    },
    /**
     * Atribute nama
     */
    name: {
      type: String,
      default: ''
    },
    /**
     * Prefix toast message yang akan tampil
     */
    labelMessage: {
      type: String,
      default: ''
    },
    int: {
      type: Boolean,
      default: false
    },
    phone: {
      type: Boolean,
      default: false
    },
    alphanumeric: {
      type: Boolean,
      default: false
    },
    regex: null,
    regexMessage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      error: false,
      valueHistory: false
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(event) {
        this.$emit('validation', event.target.value === '' ? null : event)
        const error = {
          minLength: false,
          email: false
        }
        /**
         * Untuk mendapatkan posisi select saat ini.
         */
        const start = event.target.selectionStart
        let val = event.target.value
        let spaceRemove = 0

        /**
         * Auto convert ke huruf besar
         */
        if (this.upperCase) val = val.toUpperCase()
        /**
         * Auto convert ke huruf kecil
         */
        if (this.lowerCase) val = val.toLowerCase()

        /**
         * Validasi format email
         */
        if (this.type === 'email' && val) {
          const re = /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i
          console.log(re.test(val))
          if (!re.test(val)) {
            error.email = true
            this.error = true
          } else {
            error.email = false
            this.error = false
          }
        }

        /**
         * Blok spasi dalam input
         */
        if (this.blockSpace && val !== val.replace(/\s/g, '')) {
          this.$toast.info(
            `${this.labelMessage || this.label} tidak boleh memuat spasi`
          )
          val = val.replace(/\s/g, '')
          spaceRemove = 1
        }

        /**
         * 📝 cek kondisi whitespace (atau space lebih dari satu)
         */
        val = val.replace(/\s\s+/g, ' ')
        if (event.target.value[start - 2] === ' ' && event.data === ' ') {
          spaceRemove = 1
        } else {
          this.type === 'text' && event.target.setSelectionRange(start, start)
        }
        /**
         * Validasi maksimal panjang karakter
         */
        if (this.maxLength && val.length > parseInt(this.maxLength)) {
          this.$toast.info(
            `${this.labelMessage || this.label} Maksimal ${
              this.maxLength
            } Karakter`
          )
          if (this.upperCase) {
            this.valueHistory = this.valueHistory.toUpperCase()
          }
          if (this.lowerCase) {
            this.valueHistory = this.valueHistory.toLowerCase()
          }
          val = this.valueHistory.toString().slice(0, parseInt(this.maxLength))
          spaceRemove = 1
        }
        /**
         * Validasi minimal karakter
         */
        if (val.length < parseInt(this.minLength) && val) {
          val.replace(val, '')
          error.minLength = true
          this.error = true
        } else if (val.length === parseInt(this.minLength) && val) {
          error.minLength = false
          this.error = false
        }
        /**
         * Validasi hanya nomor 0-9
         */
        if (this.type === 'number' && val !== val.replace(/[^0-9]/g, '')) {
          val = val.replace(/[^0-9]/g, '')
          spaceRemove = 1
          this.$toast.info('Hanya bisa memuat angka')
        }
        /**
         * Validasi hanya nomor 0-9,+,-,() untuk phone
         */
        if (this.phone && val !== val.replace(/[^0-9|+|-|(|)|-]/g, '')) {
          val = val.replace(/[^0-9|+|-|(|)|-]/g, '')
          spaceRemove = 1
          this.$toast.info('Hanya bisa memuat angka dan symbol +, -, ()')
        }

        if (this.alphanumeric && val !== val.replace(/[^a-z0-9]/gi, '')) {
          val = val.replace(/[^a-z0-9]/gi, '')
          spaceRemove = 1
          this.$toast.info('Hanya bisa memuat huruf dan angka')
        }

        /**
         * Konversi ke integer bila props int true
         */
        if (this.int) {
          val = parseInt(val) || val
        }

        if (this.regex && val !== val.replace(this.regex, '')) {
          val = val.replace(this.regex, '')
          spaceRemove = 1
          if (this.regexMessage) {
            this.$toast.info(this.regexMessage)
          }
        }

        /**
         * Set Value yang sudah divalidasi ke input
         */
        event.target.value = val

        /**
         * Set Histori Value
         */
        this.valueHistory = val

        /**
         * Set posisi select
         */
        event.target.setSelectionRange(start, start - spaceRemove)

        // console.log(
        //   '💻 Value: ',
        //   event.target.value,
        //   event.target.value === '' ? null : event.target.value
        // )

        /**
         * Emit value
         */
        this.$emit(
          'input',
          event.target.value === '' ? null : event.target.value
        )
      }
    }
  },
  methods: {
    handleKey(event) {
      this.$emit('keydown', event)
    }
  },
  mounted() {
    /**
     * Inisialisai Histori value
     */
    this.valueHistory = this.inputVal
  }
}
</script>

<style lang="scss">
.input__primary {
  input,
  textarea {
    @apply border;
    @apply border-transparent;
    outline: none;
  }
  input::placeholder {
    @apply text-base;
  }
  input:placeholder-shown {
    /* Your rules */
    @apply bg-neutral-9;
    @apply text-base;
  }
  input:focus {
    @apply border-primary-default;
  }
  input:disabled {
    background: #ededed !important;
    cursor: not-allowed !important;
  }
  textarea:placeholder-shown {
    /* Your rules */
    @apply bg-neutral-9;
  }
  textarea:focus {
    @apply border-primary-default;
  }
  textarea:disabled {
    background: #ededed !important;
    cursor: not-allowed !important;
  }
}
</style>
