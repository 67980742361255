import Vue from 'vue'
import Vuex from 'vuex'
import Setting from './Setting/index'
import Ecommerce from './Ecommerce/index'
import modules from './modules'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const dataState = createPersistedState({
  paths: ['authService']
})
export default new Vuex.Store({
  modules: {
    Setting,
    Ecommerce,
    ...modules
  },
  state: {},
  mutations: {},
  plugins: [dataState],
  actions: {},
  getters: {},
  strict: debug
})
