<template>
  <div class="h-full min-h-screen">
    <!-- <button style="z-index:9999;position:relative" @click="cek">cek</button> -->
    <!-- <transition name="fade">
      <div
        v-if="updateExists"
        @click="refreshApp"
        class="p-2 bg-status-info-light text-status-info flex flex-wrap place-items-center fixed w-full z-50 shadow-lg border-b"
      >
        <div class="mr-3">Terdapat pembaharuan, silahkan refresh halaman</div>
        <button-primary variant="primary-outline" class="inline mr-3"
          >Refresh</button-primary
        >
      </div>
    </transition> -->
    <router-view />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { core } from './config/pluginInit'
import jwtDecode from 'jwt-decode'
import store from '@/store'

export default {
  name: 'App',
  components: {},
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false
    }
  },
  methods: {
    ...mapActions('authService', ['postLogout'])
    // cek () {
    //   console.log('token taufan', store.state.authService.token)
    // }
    // showRefreshUI (e) {
    //   this.registration = e.detail
    //   this.updateExists = true
    // },
    // refreshApp () {
    //   this.updateExists = false
    //   if (!this.registration || !this.registration.waiting) {
    //     return
    //   }
    //   this.registration.waiting.postMessage('skipWaiting')
    // }
  },
  mounted() {
    setInterval(() => {
      const token = store.state.authService.token
      const decoded = token ? jwtDecode(token) : false
      if (new Date() >= new Date(decoded.exp * 1000) && token) {
        this.$toast.error('Sesi telah habis')
        return this.postLogout()
      }
    }, 10000)
    core.mainIndex()
  }
  // created () {
  //   document.addEventListener('swUpdated', this.showRefreshUI, { once: true })
  //   if (navigator.serviceWorker) {
  //     navigator.serviceWorker.addEventListener('controllerchange', () => {
  //       if (this.refreshing) return
  //       this.refreshing = true
  //       window.location.reload()
  //     })
  //   }
  // }
}
</script>
<style lang="scss">
@import 'assets/scss/style.scss';
</style>
