var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-select",class:[_vm.stateClasses, ..._vm.parentClass],attrs:{"dir":_vm.dir}},[(_vm.$slots.header)?_c('div',[_vm._t("header",null,null,_vm.scope.header)],2):_vm._e(),(_vm.isDetail)?_c('div',{ref:"toggle",staticClass:"vs__dropdown-toggle transition-all border cursor-not-allowed bg-light",class:[
      _vm.open ? 'border-blue-500' : '',
      _vm.errorDefault ? 'border-status-error' : '',
      _vm.value != null || _vm.open ? 'bg-white' : 'bg-neutral-9',
      _vm.size == 'md' ? 'h-10' : ''
    ],attrs:{"id":`vs${_vm.uid}__combobox`,"role":"combobox","aria-expanded":_vm.dropdownOpen.toString(),"aria-owns":`vs${_vm.uid}__listbox`,"aria-label":"Search for option"},on:{"mousedown":function($event){return _vm.toggleDropdown($event)}}},[_c('div',{ref:"selectedOptions",staticClass:"vs__selected-options"},[_vm._l((_vm.selectedValue),function(option){return _vm._t("selected-option-container",function(){return [_c('span',{key:_vm.getOptionKey(option),staticClass:"vs__selected"},[_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s(_vm.error ? _vm.errorMessage : _vm.getOptionLabel(option))+" ")]},null,_vm.normalizeOptionForSlot(option))],2)]},{"deselect":_vm.deselect,"multiple":_vm.multiple,"disabled":""})})],2)]):_c('div',{ref:"toggle",staticClass:"vs__dropdown-toggle transition-all border",class:[
      _vm.open ? 'border-blue-500' : '',
      _vm.errorDefault ? 'border-status-error' : '',
      _vm.value != null || _vm.open ? 'bg-white' : 'bg-neutral-9',
      _vm.size == 'md' ? 'h-10' : ''
    ],attrs:{"id":`vs${_vm.uid}__combobox`,"role":"combobox","aria-expanded":_vm.dropdownOpen.toString(),"aria-owns":`vs${_vm.uid}__listbox`,"aria-label":"Search for option"},on:{"mousedown":function($event){return _vm.toggleDropdown($event)}}},[_c('div',{ref:"selectedOptions",staticClass:"vs__selected-options"},[_vm._l((_vm.selectedValue),function(option){return _vm._t("selected-option-container",function(){return [_c('span',{key:_vm.getOptionKey(option),staticClass:"vs__selected"},[_vm._t("selected-option",function(){return [_vm._v(" "+_vm._s(_vm.error ? _vm.errorMessage : _vm.getOptionLabel(option))+" ")]},null,_vm.normalizeOptionForSlot(option)),(_vm.multiple)?_c('button',{ref:"deselectButtons",refInFor:true,staticClass:"vs__deselect",attrs:{"disabled":_vm.disabled || _vm.error,"type":"button","title":`Deselect ${_vm.getOptionLabel(option)}`,"aria-label":`Deselect ${_vm.getOptionLabel(option)}`},on:{"click":function($event){return _vm.deselect(option)}}},[_c(_vm.childComponents.Deselect,{tag:"component"})],1):_vm._e()],2)]},{"option":_vm.normalizeOptionForSlot(option),"deselect":_vm.deselect,"multiple":_vm.multiple,"disabled":_vm.disabled || _vm.error})}),_vm._t("search",function(){return [_c('input',_vm._g(_vm._b({staticClass:"vs__search",attrs:{"required":_vm.required && _vm.value == null ? true : false},on:{"blur":function($event){_vm.errorDefault = false},"invalid":function($event){;(_vm.errorDefault = true),
              (_vm.errorMessageDefault = `${_vm.$slots.header
                .map(item => item.text)
                .join(' ')} wajib diisi`)}}},'input',_vm.scope.search.attributes,false),_vm.scope.search.events))]},null,_vm.scope.search)],2),_c('div',{ref:"actions",staticClass:"vs__actions"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showClearButton),expression:"showClearButton"}],ref:"clearButton",staticClass:"vs__clear",attrs:{"disabled":_vm.disabled || _vm.error,"type":"button","title":"Clear Selected","aria-label":"Clear Selected"},on:{"click":_vm.clearSelection}},[_c(_vm.childComponents.Deselect,{tag:"component"})],1),_vm._t("open-indicator",function(){return [(!_vm.noDrop)?_c(_vm.childComponents.OpenIndicator,_vm._b({tag:"component"},'component',_vm.scope.openIndicator.attributes,false)):_vm._e()]},null,_vm.scope.openIndicator),_vm._t("spinner",function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mutableLoading),expression:"mutableLoading"}],staticClass:"vs__spinner"},[_vm._v("Loading...")])]},null,_vm.scope.spinner)],2)]),_c('transition',{attrs:{"name":_vm.transition}},[(_vm.dropdownOpen)?_c('ul',{directives:[{name:"append-to-body",rawName:"v-append-to-body"}],key:`vs${_vm.uid}__listbox`,ref:"dropdownMenu",staticClass:"vs__dropdown-menu top-1",style:(_vm.dropdownStyle),attrs:{"id":`vs${_vm.uid}__listbox`,"role":"listbox","tabindex":"-1"},on:{"mousedown":function($event){$event.preventDefault();return _vm.onMousedown.apply(null, arguments)},"mouseup":_vm.onMouseUp}},[_vm._t("list-header",null,null,_vm.scope.listHeader),_vm._l((_vm.filteredOptions),function(option,index){return _c('li',{key:_vm.getOptionKey(option),staticClass:"vs__dropdown-option",class:{
          'vs__dropdown-option--selected': _vm.isOptionSelected(option),
          'vs__dropdown-option--highlight': index === _vm.typeAheadPointer,
          'vs__dropdown-option--disabled': !_vm.selectable(option) || _vm.error
        },attrs:{"role":"option","id":`vs${_vm.uid}__option-${index}`,"aria-selected":index === _vm.typeAheadPointer ? true : null},on:{"mouseover":function($event){_vm.selectable(option) ? (_vm.typeAheadPointer = index) : null},"mousedown":function($event){$event.preventDefault();$event.stopPropagation();_vm.selectable(option) ? _vm.select(option) : null}}},[_vm._t("option",function(){return [_c('div',{staticClass:"flex place-items-center",class:{ 'h-12': _vm.size == 'md' }},[_vm._v(" "+_vm._s(_vm.getOptionLabel(option))+" ")])]},null,_vm.normalizeOptionForSlot(option))],2)}),(_vm.filteredOptions.length === 0)?_c('li',{staticClass:"vs__no-options"},[_vm._t("no-options",function(){return [_vm._v("Maaf, data tidak ditemukan.")]},null,_vm.scope.noOptions)],2):_vm._e(),_vm._t("list-footer",null,null,_vm.scope.listFooter)],2):_c('ul',{staticStyle:{"display":"none","visibility":"hidden"},attrs:{"id":`vs${_vm.uid}__listbox`,"role":"listbox"}})]),_vm._t("footer",null,null,_vm.scope.footer),(_vm.errorMessageDefault)?_c('div',{staticClass:"text-status-error mt-1"},[_vm._v(" "+_vm._s(_vm.errorMessageDefault)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }