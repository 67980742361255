<template>
  <div
    class="
      rounded
      p-4
      position-absolute
      w-100
      h-100
      d-flex
      align-content-center
      flex-wrap
      loading-style
    "
    v-show="loading"
  >
    <!-- <div class="mx-auto text-center">
      <div
        class="v-sync v-sync1"
        v-bind:style="[spinnerStyle, spinnerDelay1]"
      ></div>
      <div
        class="v-sync v-sync2"
        v-bind:style="[spinnerStyle, spinnerDelay2]"
      ></div>
      <div
        class="v-sync v-sync3"
        v-bind:style="[spinnerStyle, spinnerDelay3]"
      ></div>
    </div> -->
    <!-- ** PLAN A ** -->
    <div class="w-100">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img
              src="@/assets/images/aksara-logo.png"
              class="rotate-logo mx-auto d-block"
              width="250px"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- ** PLAN B ** -->
    <!-- <div class="w-100">
      <img
        src="@/assets/images/aksara-logo.png"
        class="rotate-logo mx-auto d-block"
        width="250px"
      />
    </div> -->
    <slot>
      <!-- <div class="mt-2 text-neutral-1 text-center">Sedang memuat...</div> -->
      <h1
        class="text-center font-weight-bold w-100"
        style="opacity: 1 !important"
      >
        Data Sedang Diproses, Mohon Tunggu . . .
      </h1>
    </slot>
  </div>
</template>

<script>
// import LogoAksara from '@/assets/images/aksara-logo.png'
export default {
  name: 'SyncLoader',
  components: {
    // LogoAksara
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#0055D0'
    },
    size: {
      type: String,
      default: '15px'
    },
    margin: {
      type: String,
      default: '2px'
    },
    radius: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      spinnerStyle: {
        backgroundColor: this.color,
        height: this.size,
        width: this.size,
        margin: this.margin,
        borderRadius: this.radius,
        display: 'block',
        animationName: 'v-syncStretchDelay',
        animationDuration: '0.6s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'ease-in-out',
        animationFillMode: 'both'
      },
      spinnerDelay1: {
        animationDelay: '0.07s'
      },
      spinnerDelay2: {
        animationDelay: '0.14s'
      },
      spinnerDelay3: {
        animationDelay: '0.21s'
      }
    }
  },
  mounted() {
    document.body.classList.add('overflow-hidden')
  }
}
</script>

<style>
.loading-style {
  z-index: 9999;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  left: 0;
  top: 0;
  background: var(--iq-dark-shadow-1);
}
.rotate-logo {
  animation: loading-rotate infinite 1.5s;
}

@keyframes loading-rotate {
  0% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 360deg);
  }
}

@-webkit-keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes v-syncStretchDelay {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/*
* ATOM SPINNER
*/
.mt-200 {
  margin-top: 200px;
  background-size: cover;
}
.atom-spinner,
.atom-spinner * {
  box-sizing: border-box;
}
.spinner-center {
  position: absolute;
  top: 0;
  margin: auto;
}
.atom-spinner {
  height: 600px;
  width: 600px;
  opacity: 1 !important;
  margin: auto;
  overflow: hidden;
}
.atom-spinner .spinner-inner {
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
}
.atom-spinner .spinner-circle {
  display: block;
  position: absolute;
  color: var(--iq-primary);
  font-size: calc(60px * 0.24);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.atom-spinner .spinner-line {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation-duration: 1s;
  border-left-width: calc(60px / 25);
  border-top-width: calc(60px / 25);
  border-left-color: var(--iq-primary);
  border-left-style: solid;
  border-top-style: solid;
  border-top-color: transparent;
}
.atom-spinner .spinner-line:nth-child(1) {
  animation: atom-spinner-animation-1 1s linear infinite;
  transform: rotateZ(120deg) rotateX(66deg) rotateZ(0deg);
}
.atom-spinner .spinner-line:nth-child(2) {
  animation: atom-spinner-animation-2 1s linear infinite;
  transform: rotateZ(240deg) rotateX(66deg) rotateZ(0deg);
}
.atom-spinner .spinner-line:nth-child(3) {
  animation: atom-spinner-animation-3 1s linear infinite;
  transform: rotateZ(360deg) rotateX(66deg) rotateZ(0deg);
}

@keyframes atom-spinner-animation-1 {
  100% {
    transform: rotateZ(120deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes atom-spinner-animation-2 {
  100% {
    transform: rotateZ(240deg) rotateX(66deg) rotateZ(360deg);
  }
}
@keyframes atom-spinner-animation-3 {
  100% {
    transform: rotateZ(360deg) rotateX(66deg) rotateZ(360deg);
  }
}
</style>
